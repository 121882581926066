import React from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import TeamComponent from "../components/TeamSection";
import Footer from "../components/Footer";
import RevealX from "../components/motion/RevealX";
import Reveal from "../components/motion/Reveal";
import { BsDiamondFill } from "react-icons/bs";
import ScrollToTop from "../utils/ScrooltoTop";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
const images = [
  "/images/1.jpg",
  "/images/2.jpg",
  "/images/3.jpg",
  "/images/4.jpg",
  "/images/5.jpg",
  "/images/6.jpg",
  "/images/7.jpg",
  "/images/8.jpg",
];
const Aboutpage = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="hero_area">
      <Helmet>
        <title>شهاب کمپینگ | درباره ما</title>
        <meta name="og:title" content={"شهاب کمپینگ | درباره ما"} />
      </Helmet>
      <Header />
      <ScrollToTop />
      <div dir={i18n.language === "en" ? "rtl" : "ltr"} className="bg">
        <img
          className="w-full lg:h-[500px] object-cover object-center"
          src="/about.jpg"
          alt=""
        />
        <div className="container text-[14px] lg:text-[15px]">
          <h1 className="text-3xl custom_heading text-center my-4">
            {t("about-us")}
          </h1>
          <RevealX>
            <p className="text-end mb-3">{t("about-text")}.</p>
          </RevealX>
          {/* <Reveal>
            <p className="text-end">: {t("about-service")}</p>
          </Reveal> */}
          {/* <div className="my-5 text-end">
            <Reveal>
              <h2 className=" custom_heading text-center text-xl mb-3">
                : {t("equipment")}
              </h2>
              <p className=" text-center">{t("equipment-desc")}</p>
            </Reveal>
            <Swiper
              dir="ltr"
              breakpoints={{
                0: { slidesPerView: 1, spaceBetween: 10 },
                520: { slidesPerView: 2, spaceBetween: 25 },
                786: { slidesPerView: 3, spaceBetween: 25 },
                1048: { slidesPerView: 3, spaceBetween: 25 },
              }}
              modules={[Pagination, Autoplay]}
              autoplay={{ delay: 5000 }}
              pagination
              loop
              spaceBetween={25}
              className="mt-3"
            >
              {images.map((item) => (
                <SwiperSlide>
                  <img
                    src={item}
                    className="w-full rounded p-3 border"
                    alt={t("equipment")}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="my-5 text-end">
            <Reveal>
              <h2 className=" custom_heading text-xl mb-3">
                : {t("about-service-h")}
              </h2>
            </Reveal>
            <ul className="text-[14px] lg:text-[15px]">
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("paint-service")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("paint-service-1")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("paint-service-2")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("paint-service-3")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
            </ul>
          </div>
          <div className="my-5 text-end">
            <Reveal>
              <h2 className=" custom_heading text-xl mb-3">
                : {t("about-service-polimer")}
              </h2>
            </Reveal>
            <ul className="text-[14px] lg:text-[15px]">
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("polimer-service")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("polimer-service-1")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("polimer-service-2")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("polimer-service-3")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("polimer-service-4")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
            </ul>
          </div>
          <div className="my-5 text-end">
            <Reveal>
              <h2 className=" custom_heading text-xl mb-3">
                : {t("about-service-repair")}
              </h2>
            </Reveal>
            <ul className="text-[14px] lg:text-[15px]">
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-1")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-2")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-3")} ، </p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-4")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-5")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-6")}،</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-7")} ،</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-8")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-9")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-10")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-11")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("repair-service-12")}.</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
            </ul>
          </div>
          <div className="my-5 text-end">
            <Reveal>
              <h2 className=" custom_heading text-xl mb-3">
                : {t("about-service-clean")}
              </h2>
            </Reveal>
            <Reveal head>
              <p className="mb-3">{t("about-clean-text")} .</p>
            </Reveal>
            <Reveal>
              <h2 className=" custom_heading text-xl mb-3">
                : {t("project-done")}
              </h2>
            </Reveal>
            <ul className="text-[14px] lg:text-[15px]">
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-1")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p>{t("clean-project-2")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-3")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-4")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-5")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-6")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-7")} ،</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-8")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-9")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-10")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-11")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-12")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-13")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-14")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-15")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-16")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-17")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-18")}</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
              <RevealX>
                <li className="flex justify-end items-center gap-1 my-1">
                  <p> {t("clean-project-19")}.</p>
                  <span>
                    <BsDiamondFill color="#EF4b4c" />
                  </span>
                </li>
              </RevealX>
            </ul>
          </div> */}
        </div>
        <WhySection />
        <TeamComponent />
      </div>
      {/* <InfoSection /> */}
      <Footer />
    </div>
  );
};

export default Aboutpage;
