import React from "react";
import { useTranslation } from "react-i18next";
import Reveal from "./motion/Reveal";
import { FaEnvelope, FaWhatsapp, FaPhone } from "react-icons/fa6";
import { BiPhone, BiEnvelope, BiLogoWhatsapp } from "react-icons/bi";

const ContactUs = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <section class="contact_section layout_padding relative ">
        <div
          class=" bg-[#3d619b] relative z-10  w-fit min-w-[45%] container mx-auto py-3 mb-4 px-3 rounded-lg shadow"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="grid sm:grid-cols-2 gy-5 gap-4 text-white ">
            <a class="col-lg-4" href="tel:+989136690792">
              <div class="info-item d-flex align-items-center  gap-3 hover:text-blue-300">
                <span className="border-[1px] border-[#EF4b4c] rounded-full p-[14px]">
                  <BiPhone size={25} color="#EF4b4c" />
                </span>
                <div>
                  <h3>{t("call")}</h3>
                  <p dir="ltr">+989136690792</p>
                </div>
              </div>
            </a>
            <div class="col-lg-4">
              <a
                href="mailto:info@shahabcamping.ir"
                class="info-item d-flex align-items-center  gap-3 hover:text-blue-300"
              >
                <span className="border-[1px] border-[#EF4b4c] rounded-full p-[14px]">
                  <BiEnvelope size={23} color="#EF4b4c" />
                </span>
                <div>
                  <h3>{t("email")}</h3>
                  <p>info@shahabcamping.ir</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="absolute left-0 top-0 w-full h-full bg-[#000]/50"></div>
        <Reveal head>
          <h2 class="custom_heading text-center text-[2rem]">
            {t("contact-us-now")}
          </h2>
        </Reveal>

        <div class="container mx-auto md:px-16 lg:px-40 mt-3 pt-3">
          <form dir={i18n.language === "en" ? "ltr" : "rtl"} action="">
            <Reveal>
              <div>
                <input type="text" placeholder={t("name")} />
              </div>
            </Reveal>
            <Reveal>
              <div>
                <input type="text" placeholder={t("l-name")} />
              </div>
            </Reveal>
            <Reveal>
              <div>
                <input type="text" placeholder={t("phone")} />
              </div>
            </Reveal>
            <Reveal>
              <div>
                <input
                  type="text"
                  class="message-box"
                  placeholder={t("text")}
                />
              </div>
            </Reveal>
            <Reveal head>
              <div class="flex justify-center mt-5 pt-5">
                <button>{t("send")}</button>
              </div>
            </Reveal>
          </form>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
